import React, { SyntheticEvent, useState } from 'react';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { TeacherInfo } from '../../../../types/TeacherInfo';
import { useTeacherSearchContext } from '../../../../providers/TeacherSearchProvider';
import { useReservationContext } from '../../../../providers/ReservationProvider';
import { useStudentUserContext } from '../../../../providers/StudentUserProvider';
import { trackClickButtonEvent } from '../../../../utils/functions/trackEvent';
import { Link } from 'react-router-dom';
import { useUserInteractionHistoryContext } from '../../../../providers/UserInteractionHistoryProvider';
import { CreateReservation } from '../../../../types/CreateReservation';
import { Button, ButtonColor, ButtonSize } from '../../../Button';
import { ButtonPaddingSize, ButtonVariant } from '../../../Button/Button.types';
import './ReservationForm.css';

interface ReservationFormProps {
  onSubmit: () => void;
  onBack: () => void;
  teacher: TeacherInfo;
  start: Date;
  end: Date;
}

const participantsMap = new Map()
  .set(2, { label: '2 estudiantes', value: 2, discount: '-25%' })
  .set(3, { label: '3 estudiantes', value: 3, discount: '-35%' })
  .set(4, { label: '4 o más estudiantes', value: 4, discount: '-45%' });

const ReservationForm = ({ onSubmit, onBack, teacher, start, end }: ReservationFormProps) => {
  const { selectedCourse } = useTeacherSearchContext();
  const { user, updateUser } = useStudentUserContext();
  const [isGroupClass, setIsGroupClass] = useState(false);
  const [isPlusFour, setIsPlusFour] = useState(false);
  const [plusFourError, setPlusFourError] = useState(false);

  const [participants, setParticipants] = useState(1);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(
    !!user.phoneNumber && !matchIsValidTel(user.phoneNumber)
  );
  const {
    isLoading: paymentWaiting,
    createReservation,
    reservationErrorMessage
  } = useReservationContext();
  const { addReservationIntent } = useUserInteractionHistoryContext();

  const handleSubmitButton = async () => {
    onSubmit();
    // FixMe: the course is optional for the moment, in case the users shares teachers link,
    //  we can make it mandatory it
    trackClickButtonEvent('create_reservation');
    try {
      const reservation: CreateReservation = {
        teacher_external_id: teacher.external_id,
        course_external_id: selectedCourse?.value ?? '',
        student: {
          email: user.email,
          first_name: user.name,
          last_name: user.lastname,
          phone_number: user.phoneNumber
        },
        event: { start, end },
        participants: { quantity: Number(participants) }
      };

      await createReservation(reservation);
      if (selectedCourse) {
        addReservationIntent(reservation, teacher, selectedCourse);
      }
    } catch {
      // do nothing
    }
  };
  const handleGroupClassCheckbox = (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
    setIsGroupClass(checked);
    setPlusFourError(false);
    setIsPlusFour(false);
    setParticipants(checked ? 2 : 1);
  };

  const handlePlusFourChange = (e: any) => {
    if (e.target.validity.valid) {
      setPlusFourError(false);
      setParticipants(e.target.value);
    } else {
      setPlusFourError(true);
    }
  };

  const handleGroupQuantitySelect = (event: SelectChangeEvent<any>) => {
    if (event.target.value === 4) {
      setIsPlusFour(true);
    } else {
      setIsPlusFour(false);
    }
    setParticipants(event.target.value);
  };

  const handlePhoneNumberChange = (value: any) => {
    setPhoneNumberError(!matchIsValidTel(value));
    updateUser({ phoneNumber: value });
  };

  return (
    <form className="reservationForm">
      {reservationErrorMessage && <Alert severity="error">{reservationErrorMessage}</Alert>}
      {phoneNumberError && <Alert severity="error">Teléfono invalido</Alert>}
      <div className="studentNameInformation">
        <TextField
          id="outlined-basic"
          key="name"
          placeholder="Nombre"
          variant="outlined"
          margin="dense"
          value={user.name}
          fullWidth
          style={{ marginRight: '2%' }}
          onChange={(e: any) => updateUser({ name: e.target.value })}
        />
        <TextField
          id="outlined-basic"
          key="surname"
          placeholder="Apellido"
          variant="outlined"
          margin="dense"
          fullWidth
          value={user.lastname}
          onChange={(e: any) => updateUser({ lastname: e.target.value })}
        />
      </div>
      <TextField
        id="outlined-basic"
        key="email"
        placeholder="Email"
        variant="outlined"
        margin="dense"
        required
        type="email"
        value={user.email}
        onChange={(e: any) => updateUser({ email: e.target.value })}
      />
      <MuiTelInput
        id="outlined-basic"
        key="phone"
        placeholder="Teléfono"
        variant="outlined"
        margin="dense"
        forceCallingCode
        disableFormatting
        required
        value={user.phoneNumber}
        defaultCountry="AR"
        onChange={handlePhoneNumberChange}
      />
      <div>
        <FormGroup>
          <FormControlLabel
            onChange={handleGroupClassCheckbox}
            value={isGroupClass}
            control={<Checkbox />}
            label="¿Clase grupal?"
          />
        </FormGroup>
        {isGroupClass && (
          <div className="groupClasses">
            <Typography
              variant="h6"
              component="h6"
              fontSize="0.8rem"
              color="grey"
              sx={{ marginTop: '2%', marginBottom: '2%', fontStyle: 'italic' }}>
              Se les hará un descuento por estudiante
            </Typography>
            <Select
              value={participants}
              placeholder="Cantidad estudiantes"
              onChange={handleGroupQuantitySelect}>
              <MenuItem sx={{ alignItems: 'left', width: '100%' }} value={1} disabled>
                Cantidad de estudiantes
              </MenuItem>
              {Array.from(participantsMap).map(([value, config]) => (
                <MenuItem
                  key={config?.discount}
                  sx={{ alignItems: 'center', justifyContent: 'space-between' }}
                  value={value}>
                  {config?.label} <Chip label={config?.discount} size="small" color="success" />
                </MenuItem>
              ))}
            </Select>
            {isPlusFour ? (
              <React.Fragment>
                {plusFourError && (
                  <Alert sx={{ marginTop: '4%' }} severity="error">
                    Deben ser entre 4 y 10.
                  </Alert>
                )}
                <div className="plusFour">
                  <Typography>¿Cuántos estudiantes son?</Typography>
                  <TextField
                    inputProps={{ step: 1, pattern: '/d*', min: '4', max: '10' }}
                    size="small"
                    type="number"
                    error={plusFourError}
                    onChange={handlePlusFourChange}
                  />
                </div>
              </React.Fragment>
            ) : null}
          </div>
        )}
      </div>
      <Typography
        variant="h6"
        component="h6"
        fontSize="0.8rem"
        color="grey"
        sx={{ marginTop: '2%' }}>
        Al continuar estas aceptando nuestros{' '}
        <Link className="termsButton" to={'/terms'} target="_blank" rel="noopener noreferrer">
          términos y condiciones
        </Link>{' '}
        y{' '}
        <Link className="termsButton" to={'/privacy'} target="_blank" rel="noopener noreferrer">
          políticas de privacidad.
        </Link>
      </Typography>
      <div className="reservationFormButtons">
        <Button
          variant={ButtonVariant.OUTLINED}
          color={ButtonColor.ERROR}
          size={ButtonSize.MD}
          paddingSize={ButtonPaddingSize.MD}
          onClick={onBack}>
          Volver al calendario
        </Button>
        <Button
          variant={ButtonVariant.CONTAINED}
          color={ButtonColor.PRIMARY}
          size={ButtonSize.MD}
          paddingSize={ButtonPaddingSize.MD}
          disabled={
            !(user.email && user.phoneNumber) ||
            phoneNumberError ||
            paymentWaiting ||
            (!!reservationErrorMessage && reservationErrorMessage != 'El email es invalido') ||
            (isGroupClass && isPlusFour && participants < 4)
          }
          onClick={handleSubmitButton}>
          Continuar con el pago
        </Button>
      </div>
      <Typography
        variant="h6"
        component="h6"
        fontSize="0.8rem"
        color="grey"
        sx={{ marginTop: '2%' }}>
        Con tu pago recibirás un <b>correo de confirmación</b>
        <br />
        (recordá revisar SPAM si no lo encontras)
      </Typography>
    </form>
  );
};

export default ReservationForm;
