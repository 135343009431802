import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';
import './Outline.css';

interface OutlineProps {
  withSearch?: boolean;
}

const Outline: React.FC<OutlineProps> = ({ withSearch = false }) => {
  return (
    <div className="outline">
      <Navbar withSearch={withSearch} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Outline;
