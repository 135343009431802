import React, { Fragment } from 'react';
import Search from '../Search';
import RecentInteractions from '../RecentInteractions';
import Banner from './Banner';
import './Home.css';

const Home = () => {
  return (
    <Fragment>
      <div className="home">
        <span className="homeTitle">
          Encontrá tu&nbsp;<span className="titleHighlight">profe</span>&nbsp;ideal
        </span>
        <div className="homeSearch">
          <Search />
        </div>
        <span className="helpLink">
          Para saber más entra a nuestra <a href="/help">página de ayuda</a>.
        </span>
      </div>
      <RecentInteractions />
      <Banner />
    </Fragment>
  );
};

export default Home;
