import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { Institution } from '../types/Institution';
import { Course } from '../types/Course';
import { coursesQueryFunction } from '../services/adapters/queries/coursesQueryFunction';
import { useQuery } from 'react-query';
import { institutionsQueryFunction } from '../services/adapters/queries/institutionsQueryFunction';

interface TeacherSearchContextProps {
  setCourse: (course: Course | undefined) => void;
  selectedCourse: Course | undefined;
  institutionCourses: Course[] | undefined;
  institutionCoursesLoading: boolean;
  institutions: Institution[] | undefined;
  setInstitution: (institution: Institution | undefined) => void;
  selectedInstitution: Institution | undefined;
  institutionsLoading: boolean;
  recentCourses: Course[];
  addRecentCourse: (course: Course | undefined) => void;
  clearRecentCourses: () => void;
}

const TeacherSearchContext = createContext<TeacherSearchContextProps>({
  setCourse: () => {},
  selectedCourse: undefined,
  institutionCourses: undefined,
  institutionCoursesLoading: false,
  institutions: undefined,
  setInstitution: () => {},
  selectedInstitution: undefined,
  institutionsLoading: false,
  recentCourses: [],
  addRecentCourse: () => {},
  clearRecentCourses: () => {}
});

const useInstitutionsQuery = () => {
  const institutionsQuery = institutionsQueryFunction();
  const { data: institutions, isLoading: institutionsLoading } = useQuery<Institution[]>(
    institutionsQuery.queryKey,
    institutionsQuery.queryFn
  );

  return { institutions, institutionsLoading };
};

const useCoursesQuery = (institutionIdParam: string) => {
  const [institutionId, setInstitutionId] = useState(institutionIdParam);

  const query = coursesQueryFunction(institutionId);
  const { data: courses, isLoading: coursesLoading } = useQuery<Course[]>(
    query.queryKey,
    query.queryFn
  );

  return useMemo(
    () => ({
      institutionCourses: courses,
      institutionCoursesLoading: coursesLoading,
      setInstitutionId
    }),
    [setInstitutionId, courses]
  );
};

export const useTeacherSearchContext = () => useContext(TeacherSearchContext);

interface TeacherSearchProviderProps {
  children: ReactNode;
}

export const TeacherSearchProvider: React.FC<TeacherSearchProviderProps> = ({
  children
}: TeacherSearchProviderProps) => {
  const [selectedCourse, setSelectedCourse] = useState<Course | undefined>();
  const [selectedInstitution, setSelectedInstitution] = useState<Institution | undefined>();
  const {
    institutionCourses,
    institutionCoursesLoading,
    setInstitutionId: setCoursesInstitutionId
  } = useCoursesQuery(selectedInstitution?.value ?? '');
  const { institutions, institutionsLoading } = useInstitutionsQuery();
  const [recentCourses, setRecentCourses] = useState<Course[]>([]);
  const RECENT_COURSES_STORAGE_KEY = 'recentCourses';

  useEffect(() => {
    const storedRecentCourses = localStorage.getItem(RECENT_COURSES_STORAGE_KEY);

    if (storedRecentCourses) {
      setRecentCourses(JSON.parse(storedRecentCourses));
    }
  }, []);

  const addRecentCourse = (newCourse: Course | undefined) => {
    if (newCourse) {
      setTimeout(() => {
        setRecentCourses((prevCourses) => {
          const updatedCourses = prevCourses.filter((c) => c.value !== newCourse.value);

          updatedCourses.unshift(newCourse);

          const newRecentCourses = updatedCourses.slice(0, 10);

          localStorage.setItem(RECENT_COURSES_STORAGE_KEY, JSON.stringify(newRecentCourses));

          return newRecentCourses;
        });
      }, 1000); // some delay
    }
  };

  const clearRecentCourses = () => {
    setRecentCourses([]);
    localStorage.removeItem(RECENT_COURSES_STORAGE_KEY);
  };

  const handleSetInstitution = (institutionToSet: Institution | undefined) => {
    setSelectedInstitution(institutionToSet);
    setSelectedCourse(undefined);
    setCoursesInstitutionId(institutionToSet?.value ?? '');
  };

  const value = useMemo(
    () => ({
      setCourse: setSelectedCourse,
      selectedCourse,
      institutionCourses: selectedInstitution ? institutionCourses : undefined,
      institutionCoursesLoading: selectedInstitution ? institutionCoursesLoading : false,
      institutions,
      setInstitution: handleSetInstitution,
      selectedInstitution,
      institutionsLoading,
      recentCourses,
      addRecentCourse,
      clearRecentCourses
    }),
    [
      setSelectedCourse,
      selectedCourse,
      institutionCourses,
      institutionCoursesLoading,
      institutions,
      setSelectedInstitution,
      selectedInstitution,
      institutionsLoading,
      recentCourses,
      addRecentCourse,
      clearRecentCourses
    ]
  );

  return <TeacherSearchContext.Provider value={value}>{children}</TeacherSearchContext.Provider>;
};

export default TeacherSearchProvider;
