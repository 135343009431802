import React, { Fragment, useState } from 'react';
import SearchButton from './SearchButton';
import SearchDrawer from './SearchDrawer';

const Search = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  return (
    <Fragment>
      <SearchButton onClick={() => setOpenDrawer(true)} />
      <SearchDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} />
    </Fragment>
  );
};

export default Search;
