import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTeacherSearchContext } from '../../../providers/TeacherSearchProvider';
import './SearchButton.css';

interface SearchButtonProps {
  onClick: () => void;
}

const SearchButton: React.FC<SearchButtonProps> = ({ onClick }) => {
  const { selectedInstitution, selectedCourse } = useTeacherSearchContext();

  return (
    <div className="searchButtonContainer" onClick={onClick}>
      <div className="searchIconContainer">
        <SearchIcon className="searchIcon" />
      </div>
      <div className="searchButtonSection">
        <span
          className={`searchButtonValue ${selectedInstitution?.label ? 'boldText' : 'normalText'}`}>
          {selectedInstitution?.label ? <em>{selectedInstitution.label}</em> : 'Universidad'}
        </span>
      </div>
      <div className="searchButtonDivider" />
      <div className="searchButtonSection">
        <span className={`searchButtonValue ${selectedCourse?.label ? 'boldText' : 'normalText'}`}>
          {selectedCourse?.label ? <em>{selectedCourse.label}</em> : 'Materia'}
        </span>
      </div>
    </div>
  );
};

export default SearchButton;
