import React, { useEffect } from 'react';
import { useNavigate, useNavigation, useSearchParams } from 'react-router-dom';
import { Drawer, TextField, Autocomplete, Alert } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Institution } from '../../../types/Institution';
import { Course } from '../../../types/Course';
import { useTeacherSearchContext } from '../../../providers/TeacherSearchProvider';
import { Button, ButtonColor, ButtonSize } from '../../Button';
import { ButtonPaddingSize } from '../../Button/Button.types';
import { trackClickButtonEvent, trackClickEvent } from '../../../utils/functions/trackEvent';
import './SearchDrawer.css';

interface SearchDrawerProps {
  open: boolean;
  onClose: () => void;
}

const TeacherRequestCallToAction = () => (
  <React.Fragment>
    <a href="/help/request" onClick={() => trackClickEvent('anchor', 'go_to_teacher_request_page')}>
      Escribinos
    </a>{' '}
    para encontrar tu profe ideal.
  </React.Fragment>
);

const SearchDrawer: React.FC<SearchDrawerProps> = ({ open, onClose }) => {
  const {
    institutions,
    setInstitution,
    selectedInstitution,
    setCourse,
    selectedCourse,
    institutionCourses,
    recentCourses,
    addRecentCourse
  } = useTeacherSearchContext();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const institutionId = searchParams.get('institutionId');

    if (!selectedInstitution && institutionId && institutions) {
      const institution = institutions.find((inst) => inst.value === institutionId);

      if (institution) {
        setInstitution(institution);
      }
    }
  }, [selectedInstitution, searchParams, institutions, setInstitution]);

  useEffect(() => {
    const courseId = searchParams.get('courseId');

    if (!selectedCourse && selectedInstitution && courseId && institutionCourses) {
      const course = institutionCourses.find((crs) => crs.value === courseId);

      if (course) {
        setCourse(course);
      }
    }
  }, [selectedCourse, searchParams, selectedInstitution, institutionCourses, setCourse]);

  const handleSearch = (institution: Institution, course: Course) => {
    addRecentCourse(course);
    trackClickButtonEvent('search');
    navigate(`/teachers?institutionId=${institution?.value}&courseId=${course?.value}`);
    onClose();
  };

  const handleClickSearch = () => {
    if (!selectedInstitution || !selectedCourse) {
      return;
    }
    handleSearch(selectedInstitution, selectedCourse);
  };

  const handleRecentCourseClick = (recentCourse: Course) => {
    setInstitution(recentCourse.institution);
    setCourse(recentCourse);
    trackClickButtonEvent('recent_course_search');
    handleSearch(recentCourse.institution, recentCourse);
  };

  const handleInstitutionChange = (changedInstitution: Institution | undefined, reason: string) => {
    if (reason === 'clear') {
      setCourse(undefined);
      setInstitution(undefined);
    } else {
      setCourse(undefined);
      setInstitution(changedInstitution);
    }
  };

  const handleCourseChange = (courseChanged: Course | undefined, reason: string) => {
    if (reason === 'clear') {
      setCourse(undefined);
    } else {
      setCourse(courseChanged);
      // Cerrar el teclado forzando el blur en el input
      (document.activeElement as HTMLElement)?.blur();
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="top"
      PaperProps={{
        sx: { height: '100%' }
      }}>
      <div className="searchDrawerForm">
        <div className="searchDrawerHeader">
          <span className="cancelSearch" onClick={onClose}>
            Cancelar
          </span>
          <Autocomplete
            id="institutions"
            autoHighlight
            inputMode="search"
            noOptionsText={<TeacherRequestCallToAction />}
            disableClearable
            freeSolo={false}
            options={institutions ?? []}
            value={selectedInstitution}
            fullWidth
            getOptionLabel={(option: Institution) => option.label}
            autoComplete
            includeInputInList
            onChange={(_, value, reason) => handleInstitutionChange(value, reason)}
            renderInput={(params) => (
              <TextField
                {...params}
                className="autocompleteField"
                placeholder="Universidad"
                name="institutionId"
                variant="outlined"
                margin="dense"
                required
                autoFocus
              />
            )}
          />
          <Autocomplete
            id="courses"
            autoHighlight
            inputMode="search"
            noOptionsText={<TeacherRequestCallToAction />}
            disableClearable
            freeSolo={false}
            options={institutionCourses ?? []}
            value={selectedCourse}
            fullWidth
            getOptionLabel={(option: Course) => option.label}
            autoComplete
            includeInputInList
            onChange={(_, value, reason) => handleCourseChange(value ?? undefined, reason)}
            renderInput={(params) => (
              <TextField
                {...params}
                className="autocompleteField"
                placeholder="Materia"
                name="courseId"
                variant="outlined"
                margin="dense"
                required
                autoFocus
              />
            )}
            disabled={!institutionCourses || institutionCourses.length < 1}
          />
        </div>

        {recentCourses.length > 0 && (
          <div className="recentCoursesContainer">
            <h3 className="recentCoursesTitle">Materias recientes</h3>
            <ul className="recentCoursesList">
              {recentCourses.slice(0, 8).map((recentCourse) => (
                <li
                  key={recentCourse.value}
                  className="recentCourseItem"
                  onClick={() => handleRecentCourseClick(recentCourse)}>
                  <SearchIcon fontSize="small" /> {recentCourse.label}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="searchDrawerButtonContainer">
          {(!selectedInstitution || !selectedCourse) && (
            <Alert severity="info">
              ¿No encontraste tu universidad o materia?
              <br />
              <TeacherRequestCallToAction />
            </Alert>
          )}
          <Button
            color={ButtonColor.PRIMARY}
            size={ButtonSize.LG}
            paddingSize={ButtonPaddingSize.MD}
            type="submit"
            className="searchDrawerButton"
            onClick={handleClickSearch}
            isLoading={navigation.state === 'loading'}
            disabled={!selectedCourse || !selectedInstitution}>
            Buscar
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default SearchDrawer;
