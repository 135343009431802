import React, { useEffect, useState } from 'react';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import classNames from 'classnames';
import { useAsyncValue } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { trackClickEvent } from '../../utils/functions/trackEvent';
import { CalendarInfo } from '../../types/CalendarInfo';
import { FormControlLabel, Radio } from '@mui/material';
import { Button, ButtonColor, ButtonSize } from '../Button';
import { ButtonPaddingSize } from '../Button/Button.types';
import './Calendar.css';

export interface SelectedSlot {
  start: Date;
  startStr: String;
  end: Date;
  endStr: String;
}

const TeachersCalendar = ({
  businessHours,
  handleConfirmation,
  teacherExternalId,
  reservationType
}: {
  businessHours: any;
  handleConfirmation: (selectedSlot: any) => void;
  teacherExternalId: string;
  reservationType: string;
}) => {
  const [userReachCalendar, setUserReachCalendar] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<SelectedSlot>();
  const [slotDuration, setSlotDuration] = useState<number>(1);

  const calendarData = useAsyncValue();

  const { to, events } = calendarData as CalendarInfo;

  const handleSelected = (info: any) => {
    setSelectedSlot(info);
    if (!userReachCalendar) {
      setUserReachCalendar(true);
      trackClickEvent('calendar_slot', teacherExternalId);
    }
  };

  const handleSlotConfirmation = () => {
    handleConfirmation(selectedSlot);
  };

  const smallDevice = useMediaQuery('(max-width:768px)');

  const handleContentLoaded = () => {
    let calendarEl: HTMLElement = document.getElementById('calendar')!;

    let calendar = new Calendar(calendarEl, {
      plugins: [interactionPlugin, timeGridPlugin],
      initialView: 'customView',
      views: {
        customView: {
          type: 'timeGrid',
          duration: { days: smallDevice ? 2 : 7 }
        }
      },
      headerToolbar: {
        start: 'title',
        center: '',
        end: 'prev,next'
      },
      contentHeight: smallDevice ? 600 : 'auto',
      longPressDelay: 1,
      navLinks: false,
      editable: true,
      locale: esLocale,
      eventStartEditable: false,
      selectable: true,
      selectConstraint: 'businessHours',
      selectOverlap: false,
      eventOverlap: false,
      allDaySlot: false,
      snapDuration: '00:30:00',
      expandRows: true,
      unselectAuto: true,
      nowIndicator: false,
      slotDuration: '01:00:00',
      slotMinTime: '08:00:00',
      slotMaxTime: '22:00:00',
      defaultTimedEventDuration: '01:00:00',
      forceEventDuration: true,
      validRange: function (nowDate) {
        let startDate = new Date(nowDate.getTime() + 3 * 36e5);

        const dayOffset = startDate.getHours() >= 21 ? 2 : 1;

        startDate.setDate(nowDate.getDate() + dayOffset);

        return {
          start: startDate,
          end: to
        };
      },
      businessHours: businessHours,
      select: (info: any) => {
        const newEndTime = new Date(info.start);

        newEndTime.setMinutes(newEndTime.getMinutes() + slotDuration * 60);

        // Check for overlap with existing events
        const existingEvents = calendar.getEvents().filter((event: any) => {
          return (
            // Overlapping events
            (info.start >= event.start && info.start < event.end) ||
            (newEndTime > event.start && newEndTime <= event.end) ||
            // Contained events
            (info.start < event.start && newEndTime > event.end)
          );
        });

        if (existingEvents.length > 0) {
          calendar.unselect();

          return;
        }

        if (Math.abs(info.start - info.end) / 36e5 < slotDuration) {
          let minEndDate: Date = new Date(info.start);

          minEndDate.setMinutes(minEndDate.getMinutes() + slotDuration * 60);
          info.end = minEndDate;
          handleSelected(info);
          calendar.select(info);
        } else if (Math.abs(info.start - info.end) / 36e5 > 4) {
          calendar.unselect();
        } else {
          handleSelected(info);
        }
      },
      selectAllow: function (select) {
        const today = new Date();

        return select.start >= new Date(today.getTime() + 24 * 36e5);
      },
      dayMaxEvents: true, // allow "more" link when too many events
      events: events
        .map((e) => ({
          start: e.start,
          end: e.end,
          editable: false,
          title: e.type === 'reservation_in_progress' ? 'Pendiente' : 'Ocupado',
          backgroundColor: e.type === 'reservation_in_progress' ? '#F9BF6D' : '#78818C',
          borderColor: e.type === 'reservation_in_progress' ? '#F9BF6D' : '#78818C'
        }))
        .concat({
          start: new Date().toISOString(),
          end: new Date(new Date().getTime() + 24 * 36e5).toISOString(),
          editable: false,
          title: 'No disponible',
          backgroundColor: '#78818C',
          borderColor: '#78818C'
        })
    });

    calendar.render();
  };

  useEffect(() => {
    handleContentLoaded();
  }, [calendarData, slotDuration]);

  const handleSlotDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSlotDuration(Number(event.target.value));
  };

  return (
    <div className="calendarContainer">
      <h4 className="calendarTitle">Elegí horario para tu clase</h4>
      <div className="radioSlotDuration">
        <FormControlLabel
          control={
            <Radio value={1} checked={slotDuration === 1} onChange={handleSlotDurationChange} />
          }
          label="1 hora"
        />
        <FormControlLabel
          control={
            <Radio value={1.5} checked={slotDuration === 1.5} onChange={handleSlotDurationChange} />
          }
          label="1.5 horas"
        />
        <FormControlLabel
          control={
            <Radio value={2} checked={slotDuration === 2} onChange={handleSlotDurationChange} />
          }
          label="2 horas"
        />
      </div>
      <div className="calendar" id="calendar" />
      {userReachCalendar && (
        <Button
          color={ButtonColor.PRIMARY}
          size={ButtonSize.MD}
          paddingSize={ButtonPaddingSize.MD}
          className={classNames('calendarButton', {
            ['calendarButtonDisabled']: !selectedSlot?.startStr
          })}
          disabled={!selectedSlot?.startStr}
          onClick={handleSlotConfirmation}>
          {`${reservationType} tu clase`}
        </Button>
      )}
    </div>
  );
};

export default TeachersCalendar;
