import React, { Fragment } from 'react';
import RecentCourseCard from './RecentCourseCard';
import { useUserInteractionHistoryContext } from '../../providers/UserInteractionHistoryProvider';
import './RecentInteractions.css';
import Carousel from '../Carousel';
import CarouselItem from '../Carousel/CarrouselItem';
import { Divider } from '@mui/material';

const RecentInteractions: React.FC = () => {
  const { reservationIntents } = useUserInteractionHistoryContext();

  const filteredReservationIntents = React.useMemo(() => {
    if (!reservationIntents || reservationIntents.length === 0) {
      return [];
    }

    const threeMonthsAgo = new Date();

    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    // Filter unique teacher-course combinations and exclude old reservations
    const uniqueReservationIntents = new Map<string, typeof reservationIntents[0]>();

    reservationIntents.forEach((intent) => {
      const isRecent = new Date(intent.reservation.event.start) >= threeMonthsAgo;
      const uniqueKey = `${intent.course.value}-${intent.teacher.external_id}`;

      if (isRecent && !uniqueReservationIntents.has(uniqueKey)) {
        uniqueReservationIntents.set(uniqueKey, intent);
      }
    });

    return Array.from(uniqueReservationIntents.values());
  }, [reservationIntents]);

  if (!filteredReservationIntents || filteredReservationIntents.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <section className="recentInteractions">
        <h2>Volvé a tomar clases</h2>
        <Carousel>
          {filteredReservationIntents.map(({ course, teacher }) => (
            <CarouselItem key={`${course.value}-${teacher.external_id}`}>
              <RecentCourseCard course={course} teacher={teacher} />
            </CarouselItem>
          ))}
        </Carousel>
      </section>
      <Divider sx={{ width: '100%' }} />
    </Fragment>
  );
};

export default RecentInteractions;
